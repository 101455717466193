import { useState } from "react"
import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"

import { MonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCardV2"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import { useFetchResponseServiceProvider } from "src/data/homes/queries/responseServiceQueries"
import { IResponseService } from "src/data/homes/types/responseServiceTypes"
import { usePutHomeDisturbance } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { palette } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"
import { spacing } from "src/ui/spacing"

export function GuardAssistCard({
  orgId,
  homeId,
  homeName,
  responseService,
  monitoringState,
  stateUpdatedAt,
}: {
  orgId: string
  homeId: string
  homeName: string
  responseService: IResponseService | undefined
  monitoringState: NoiseMonitoringState | undefined
  stateUpdatedAt: string | undefined
}) {
  const { t, langKeys } = useTranslate()

  const [showDispatchDialog, setShowDispatchDialog] = useState(false)
  const [manualDispatchPushedAt, setManualDispatchPushedAt] = useState(
    new Date(0) // set initial date to beginning of time
  )

  const putHomeDisturbance = usePutHomeDisturbance()

  const fetchResponseServiceProvider = useFetchResponseServiceProvider({
    providerId: responseService?.provider || "",
    options: {
      enabled: !!responseService?.provider,
    },
  })

  if (!responseService?.active || !monitoringState || !stateUpdatedAt) {
    return null
  }

  const monitoringStateUpdatedAt = new Date(stateUpdatedAt)

  const shouldDisableManualDispatchButton =
    (monitoringState === "level_3" && !!responseService?.dispatch_status) ||
    monitoringState === "level_4" ||
    monitoringState === "snoozed" ||
    monitoringState === "idle" ||
    manualDispatchPushedAt >= monitoringStateUpdatedAt

  function handleManualDispatch() {
    setShowDispatchDialog(false)
    setManualDispatchPushedAt(new Date())
    putHomeDisturbance.mutate({
      orgId,
      homeId,
      body: {
        action: "dispatch",
      },
    })
  }

  return (
    <div>
      <MonitoringCardV2
        icon={<ResponseServiceIcon height={40} color={palette.hav} />}
        title={t(langKeys.response_service)}
        action={
          <MButton
            variant="subtle"
            onClick={() => {
              setShowDispatchDialog(true)
            }}
            loading={putHomeDisturbance.isLoading}
            disabled={shouldDisableManualDispatchButton}
          >
            {t(langKeys.disturbance_dispatch_manually_action)}
          </MButton>
        }
        status={
          <Status
            loading={fetchResponseServiceProvider.isLoading}
            statusDescription={t(langKeys.response_service_by_provider, {
              provider: fetchResponseServiceProvider.data?.name,
            })}
          />
        }
      />

      {showDispatchDialog && (
        <ConfirmDialog
          onClose={() => setShowDispatchDialog(false)}
          onConfirm={() => handleManualDispatch()}
          title={t(langKeys.disturbance_dispatch_manually_dialog_title, [
            homeName,
          ])}
          confirmLabel={t(langKeys.disturbance_dispatch_manually_action)}
          open={true}
        >
          {t(langKeys.disturbance_dispatch_manually_dialog_message, [
            fetchResponseServiceProvider.data?.name,
          ])}
        </ConfirmDialog>
      )}
    </div>
  )
}

function Status({
  loading,
  statusDescription,
}: {
  loading?: boolean
  statusDescription?: string
}) {
  if (loading) {
    return (
      <StyledIconBox>
        <CircularProgress />
      </StyledIconBox>
    )
  }

  return (
    <StyledIconBox>
      <CheckCircleIcon svgProps={{ width: 20 }} />
      {statusDescription}
    </StyledIconBox>
  )
}

const StyledIconBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
